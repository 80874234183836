import Head from "next/head";
import { FC } from "react";

type Props = {
  title?: string;
};

const Header: FC<Props> = ({ title = "" }) => {
  return (
    <Head>
      <link rel="icon" href="/images/favicon.png" />
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Head>
  );
};

export default Header;
