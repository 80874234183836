import { FC, ReactNode, useEffect } from "react";
import Image from "next/image";
import Header from "./partials/Header";
import { setCookie, getCookie } from "cookies-next";
import { useRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";

type Props = {
  children: ReactNode;
  bg: string;
  src: string;
  bgColor: string;
  type?: string;
};

const AuthLayout: FC<Props> = ({ children, bg, src, bgColor, type = 'sign_up' }) => {
  const router = useRouter();
  useEffect(() => {
    document.body.classList.add("flex");
    document.body.classList.add("h-full");
    document.body.classList.add("flex-col");
  }, []);

  useEffect(() => {
    // @ts-ignore
    analytics.ready(function () {
      // @ts-ignore
      setCookie("anonId", analytics.user().anonymousId());

      // @ts-ignore
      analytics.page("viewed");
    });
  }, [router]);

  const color = {
    primary: "bg-primary-900",
    purple: "bg-purple-900",
  } as any;

  return (
    <>
      <Header />
      <div
        className={`relative flex min-h-screen justify-center md:px-12 lg:px-0 ${color[bgColor]}`}
      >
        <div
          className={`lg:w-1/2 relative z-10 flex flex-1 flex-col ${bg} bg-top bg-no-repeat bg-cover py-10 px-4 justify-center -mt-20 md:flex-none md:px-28`}
        >
          {children}
        </div>
        <div
          className={`hidden md:relative lg:flex lg:flex-1 ${color[bgColor]} sm:justify-center py-10 px-4 md:flex-none`}
        >
          <div className="mx-auto w-full sm:px-4 md:w-auto md:px-0 hidden lg:flex lg:flex-col lg:justify-center lg:space-y-10">
            <Image
              src={`/images/bg/${src}.svg`}
              alt="Storage"
              width={532}
              height={563}
              priority
            />
            {bg === "bg-blur" ? (
              <div className="flex flex-col items-center justify-center px-12 space-y-3.5 text-white text-center">
                <p className="font-bold text-title3 ">
                  {type == 'sign_up' ? 'Register to find storage unit auctions happening near you.' : 'Sign in to find storage unit auctions happening near you.'}
                </p>
                <p className="text-small-normal w-[25.938rem] text-center">
                  Using Storage Unit auction List can help you save time and
                  make more money.
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center px-12 space-y-3.5 text-white text-center">
                <p className="font-bold text-title3 ">
                  {type == 'sign_up' ? 'Register for free to manage your facility and auctions.' : 'Sign in to manage your facility and auctions' }
                </p>
                <p className="text-small-normal w-[25.938rem] text-center">
                  Storage Unit Auction List helps storage unit facilities find
                  more potential buyers by making the process of listing storage
                  auctions simpler.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
